import React, { useState, useEffect } from "react";
import ClaimService from "../../../../services/ClaimService";
import Notifications from "../../../../services/Notifications";

const PumpManufacturerSelector = ({
  localized,
  visible,
  pumpManufacturer,
  setPumpManufacturer,
}) => {
  const [pumpManufacturers, setPumpManufacturers] = useState([]);

  useEffect(() => {
    const fetchManufacturers = async () => {
      try {
        const response = await ClaimService.fetchPumpManufacturers();
        if (!response && response.length === 0) {
          Notifications.error("Failed to fetch pump manufacturers");
          return;
        }

        setPumpManufacturers(response);
      } catch (error) {
        console.error("Error fetching pump manufacturers:", error);
        Notifications.error("Failed to fetch pump manufacturers");
      }
    };

    fetchManufacturers();
  }, []);

  if (!visible) {
    return null;
  }

  return (
    <div className="claim-replacement-option">
      <div className="label">{localized.PumpManufacturer}</div>
      <div className="fele-content">
        <select
          style={{ lineHeight: 2.2, fontSize: "small" }}
          onChange={(e) => setPumpManufacturer(e.target.value)}
          value={pumpManufacturer}
          className="form-select"
          required
        >
          <option value="">{localized.Select}</option>
          {pumpManufacturers.map((pumpManufacturer) => (
            <option key={pumpManufacturer} value={pumpManufacturer}>
              {pumpManufacturer}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default PumpManufacturerSelector;
